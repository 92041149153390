html, body {
  font-family: 'Montserrat', sans-serif;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 1450px)  {
  html, body {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1100px)  {
  html, body {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1000px)  {
  html, body {
    font-size: 10px;
  }
}

@media only screen and (max-width: 900px)  {
  html, body {
    font-size: 8px;
  }
}

@media only screen and (max-width: 700px)  {
  html, body {
    font-size: 8px;
  }
  h1{
    max-width: min-content;
  }
}

.App {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.darken {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  isolation: isolate;
}

.darken::after {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: .4;
}

h1 {
  margin: 0;
  margin-top: calc(40vh);
  font-size: 8rem;
  font-weight: 600;
  text-transform: uppercase;
  opacity: .9;
}

h2 {
  margin: 0 0 0 0;
  font-size: 3rem;
  font-weight: 100;
}

h3 {
  margin-top: 5vh;
  font-size: 4rem;
  font-weight: 300;
  font-style: italic;
  opacity: .9;
}

footer {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}

h4 { 
  font-size: 2rem;
  font-weight: 100;
}

.right {
  margin-left: auto;
  margin-right: 3rem;
}

.left {
  margin-right: auto;
  margin-left: 3rem;
}

.footer-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}



